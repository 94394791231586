import { combineReducers }   from "redux";
import   currentUser,
       { BaseUserState }     from "./currentUser";
import   error,
       { BaseErrorState }    from "./errors";

export interface DefaultRootState
{
    currentUser: BaseUserState,
    error: BaseErrorState
}


const rootReducer = combineReducers({
    currentUser,
    error
});

export default rootReducer;