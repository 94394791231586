import   React,
       {
         useState,
         useCallback
       }                 from "react";
import {
         makeStyles,
         Theme,
         createStyles
       }                 from "@material-ui/core/styles";
import   clsx            from "clsx";
import   Alert,
       { AlertProps }    from "@material-ui/lab/Alert";
import   IconButton      from "@material-ui/core/IconButton";
import   Collapse        from "@material-ui/core/Collapse";
import   CloseIcon       from "@material-ui/icons/Close";
import   Typography      from "@material-ui/core/Typography";
import   BasicTextLink   from "../BasicLink/BasicLink";


interface Props
{
    msg:         string;
    icon?:       boolean;
    fixBottom?:  boolean;
    severity?:   AlertProps["severity"]
    to?:         string;
    isWeb?:      boolean;
    onClose?:    () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
        root:
        {
            width: "100%",
            "& > * + *":
            {
                marginTop: theme.spacing(2)
            }
        },

        fixBottom:
        {

            position: "fixed",
            bottom: 0,
            zIndex: 1200
        },

        alertBox:
        {
            "& > div.MuiAlert-message":
            {
                width: "100%"
            }
        },

        alertLink:
        {
            color: theme.palette.text.primary,
            transition: "color 0.5s linear",

            "&:hover":
            {
                color: theme.palette.primary.main
            }
        }
    })
);

const BasicAlert : React.FC<Props> = React.memo(({msg, icon=true, fixBottom=false, severity="info", to, isWeb=false, onClose}) =>
{
    const [ open, setOpen ] = useState(true);

    const handleClose = useCallback(() =>
    {
        setOpen(false);
        if (onClose) onClose();
    }, [ onClose ]);
    
    const classes = useStyles();
    return (
        <div className={clsx(classes.root,
             {
                [classes.fixBottom]: fixBottom
             })}>
            <Collapse in={open}>
                <Alert
                    severity={severity}
                    className={classes.alertBox}
                    action={
                        icon ? <IconButton
                            aria-label={"close"}
                            color={"inherit"}
                            size={"small"}
                            onClick={handleClose}>
                            <CloseIcon fontSize={"inherit"} />
                        </IconButton>
                        :
                        undefined
                    }>
                    {
                        to ?
                        <BasicTextLink to={to} isWeb={isWeb}>
                            <Typography variant={"body1"} align={"center"} color={"textSecondary"} className={classes.alertLink}>
                                {msg}
                            </Typography>
                        </BasicTextLink>
                        :
                        <Typography variant={"body1"} align={"center"} color={"textPrimary"}>
                            {msg}
                        </Typography>
                    }
                </Alert>
            </Collapse>
        </div>
    );
});

export default BasicAlert;