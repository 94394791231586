import React            from "react";
import {
         SUSPENSE_LOADING_TEXT
       }                from "../../constants/strings";

import "./fallbackMain.css";

interface Props
{
    isComponent? : boolean
}

const SuspenseFallbackMain : React.FC<Props> = React.memo(({isComponent}) =>
{
    return isComponent ?
    (
        <div className={"spinnerComp"}></div>
    )

    :
    
    (
        <div className={"loaderBg spinner"}>
            <img
                src={require("../../assets/images/logo/Logo.png")}
                alt={"Channel Jewelry logo"}
                role={"presentation"}
                width={200} />
            <h1 className={"loadingText"}>{SUSPENSE_LOADING_TEXT}</h1>
        </div>
    )
});

export default SuspenseFallbackMain;