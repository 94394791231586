import axios, { AxiosRequestConfig } from "axios";

export function setTokenHeader(token : string)
{
    // Not calling .trim on token
    // purposefully. We don't want
    // to modify any given token.
    if(token.length > 0)
    {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    else
    {
        delete axios.defaults.headers.common["Authorization"];
    }
}

export enum ValidAPICallMethods
{
    GET    = "get",
    POST   = "post",
    PUT    = "put",
    PATCH  = "patch",
    DELETE = "delete"
}

export type APICallResponse = { data: any, status: number };

export function apiCall(method : ValidAPICallMethods, url : string, data?: { [ key : string ] : any }, options? : Pick<AxiosRequestConfig, "headers" | "responseType" | "onUploadProgress">) : Promise<APICallResponse>
{
    return new Promise((resolve, rej) =>
    {
        return axios({ method, url, data, ...options })
                .then(res => resolve({ data: res.data, status: res.status }))
                .catch((err) => rej(err));
    });
}