import { Action }           from "redux";
import {
         DesignationDomain,
         UserDomain
       }                    from "../../components/SignUpForm/SignUpForm";
import { UserActionTypes }  from "../actionTypes";

export type ReduxUser = UserDomain<string> & DesignationDomain &
{
    role:       string,
    status:     string,
    notes:      string,
    _id:        string,
    password:   string,
    token: {
             code: string,
             exp:  Date
           },
    createdAt: Date,
    updatedAt: Date,
    __v: number
};

export interface BaseUserState
{
    isAuthenticated: boolean;
    user:            ReduxUser | {}
}

interface UserReducerAction extends Action
{
    type : UserActionTypes,
    user:  ReduxUser | {}
}

const DEFAULT_STATE : BaseUserState =
{
    isAuthenticated: false,
    user: { }
};

export default (state : BaseUserState = DEFAULT_STATE, action : UserReducerAction) =>
{
    switch (action.type)
    {
        case UserActionTypes.SET_CURRENT_USER:
            return { 
                        ...state,
                        isAuthenticated:    !!Object.keys(action.user).length,
                        user:               action.user
                   };
    
        default:
            return state;
    }
}