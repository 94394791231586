export enum UserActionTypes
{
    SET_CURRENT_USER   = "SET_CURRENT_USER",
}

export enum ErrorActionTypes
{
    ADD_ERROR     =  "ADD_ERROR",
    REMOVE_ERROR  =  "REMOVE_ERROR"
}



export const ADD_ERROR          =   ErrorActionTypes.ADD_ERROR;
export const REMOVE_ERROR       =   ErrorActionTypes.REMOVE_ERROR;
export const SET_CURRENT_USER   =   UserActionTypes.SET_CURRENT_USER;