import {
         Theme,
         makeStyles,
         createStyles
       }                         from "@material-ui/core/styles";
import {
         themeOne,
         standardLetterSpacing
       }                         from "./globalThemeVariables";

export const useNavbarStyles = makeStyles((theme : Theme) => createStyles({

    flxSpcBtwnCen:
    {
        display:          "flex",
        justifyContent:   "space-between",
        alignItems:       "center"
    },

    topNavMain:
    {
        position:         "sticky",
        top:               0,
        width:            "100%",
        zIndex:            50,
        backgroundColor:   themeOne.textColorPrimaryExtended.tertiary,
        paddingBottom:     0,
        transition:        "top 0.9s",
        // breakpoint must match breakpoint for mobile nav display in RenderNav
        [theme.breakpoints.up("lg")]:
        {
            "&":
            {
                borderBottom:  "solid 2px " + theme.palette.primary.dark,
                paddingBottom: "1rem"
            }
        }
    },

    topBar:
    {
        backgroundColor:   theme.palette.primary.main,
        padding:          "5px 0.625rem"
    },

    topNavLinkBase:
    {
        fontFamily:        theme.typography.fontFamily,
        fontSize:         "1rem",
        color:             themeOne.textColorPrimaryExtended.tertiary,
        letterSpacing:     standardLetterSpacing,
        transition:       "color 0.150s linear",
        cursor:           "pointer",
        marginTop:        "0.25rem",
        marginBottom:     "0.25rem",

        "& > *, & *, & > * > *, & * *":
        {
            letterSpacing:     standardLetterSpacing
        },

        "&:hover":
        {
            color: theme.palette.primary.light
        }
    },

    topNavLink:
    {
        textTransform:    "capitalize",
        cursor:           "pointer",
        letterSpacing:     standardLetterSpacing
    },

    topNavLinkWorkweek:
    {
        textTransform:    "capitalize"
    },

    topLeftLinks:
    {
        width:            "12rem"
    },

    topRightLinks:
    {
        width:            "35rem",
        lineHeight:       "0.625rem"
    },

    verticalDivider:
    {
        backgroundColor:   themeOne.textColorPrimaryExtended.tertiary,
        height:           "0.875rem",
        marginTop:        "0.5rem"
    },

    verticalDividerRight:
    {
        backgroundColor:   themeOne.textColorPrimaryExtended.tertiary,
        height:           "0.875rem",
        margin:           "0 5px",
        marginTop:        "0.5rem"
    },

    menuDropdownIcon:
    {
        color:             themeOne.textColorPrimaryExtended.tertiary,
        fontSize:         "1.2rem",
        cursor:           "pointer",
        transition:       "color 0.150s linear",
        marginTop:        "0.43rem",

        "&:hover":
        {
            color: theme.palette.primary.light
        }
    },

    borderBarSeparator:
    {
        backgroundColor:   theme.palette.secondary.main
    }
}));

export const useBottomNavbarStyles = makeStyles((theme : Theme) => createStyles({
        bottomNav:
        {
            display:           "flex",
            justifyContent:    "center",
            marginBottom:      "-0.75rem",
            paddingBottom:      0,
            padding:           "0 1rem",
            backgroundColor:    "#fafafa"
        },

        logoBannerLeft:
        {
            display:           "flex",
            alignItems:        "center",
            marginTop:         "0.75rem"
        },

        brandNameMain:
        {
            color:              theme.palette.primary.main,
            fontFamily:         theme.typography.fontFamily,
            marginTop:         "0.8rem"
        },

        brandNameInfo:
        {
            color:              theme.palette.text.secondary,
            fontFamily:         theme.typography.fontFamily,
            fontWeight:         theme.typography.fontWeightLight,
            textAlign:         "left",
            marginLeft:        "5px",
            marginTop:         "-0.9375rem"
        },

        menuExtras:
        {
            width:               "100%",
            height:              "100%",
            marginTop:           "0.625rem",
            marginBottom:        "0.7rem",
            display:             "flex",
            justifyContent:      "flex-end",
            alignItems:          "center"
        },

        menuExtrasSecondary:
        {
            marginTop: "1rem",
            "& > form":
            {
                width: "100%",
                display:             "flex",
                justifyContent:      "flex-end",
                alignItems:          "center"
            }
        },

        helperIcon:
        {
            color:                theme.palette.primary.main,
            fontSize:            "1.1rem",
            "& *, & > *":
            {
                transition:          "color 0.2s linear"
            }
        },

        topMenuListItems:
        {
            width:             "12rem",
            margin:            "0 0.625rem",
            borderRadius:      "0.3rem",
            display:           "flex",
            flexDirection:     "column",
            paddingBottom:      0,
            "&:first-child":
            {
                width:         "16rem"
            },
            "& > div > p > .mainMenuTitles":
            {
                transition: "color 0.2s linear"
            },

            "&:hover":
            {
                backgroundColor:      theme.palette.primary.main,
                cursor:              "pointer",
                borderRadius:        "0.3rem 0.3rem 0 0",

                "& > div > .mainMenuTitles":
                {
                    color: themeOne.textColorPrimaryExtended.tertiary
                }
            }
        },

        menuTitleStyles:
        {
            textAlign:         "center",
            fontSize:          "1.2rem",
            textTransform:     "uppercase",
            fontFamily:        theme.typography.fontFamily,

            [theme.breakpoints.down("md")]:
            {
                fontSize: "0.8rem"
            }
        },

        menuTitleLinks:
        {
            textDecoration:     "none",
            color:               theme.palette.text.primary,
            transition:         "all 0.15s linear",
            letterSpacing:      "3px",
            fontSize:           "1.2rem",
            "&:hover":
            {
                textDecoration: "none"
            }
        },

        dropdownStyles:
        {
            width:                   "12rem",
            backgroundColor:          theme.palette.primary.main,
            position:                "absolute",
            top:                     "13.5rem",
            overflow:                "hidden",
            borderBottomLeftRadius:  "0.3rem",
            borderBottomRightRadius: "0.3rem",
            [theme.breakpoints.down("md")]:
            {
                top: "2rem"
            }
        },

        dropdownMenuList:
        {
            listStyleType:     "none",
            padding:           "0",
            paddingBottom:     "0",
            display:           "flex",
            flexDirection:     "column",
            justifyContent:    "space-between",

            "& > li:hover":
            {
                backgroundColor: theme.palette.secondary.main,
                "& > div > p > a:hover":
                {
                    textDecoration: "none"
                }
            }
        },

        menuLinkBox:
        {
            padding: "0",
            margin:  "-15px initial",
            display: "flex",
            justifyContent: "space-between",
            
            "&:first-of-type":
            {
                marginTop:      "0.625rem"
            },

            "&:hover > div > p > a":
            {
                color: theme.palette.primary.main
            }
        },

        menuListItemTextContainer:
        {
            textAlign:           "left",
            fontSize:            "1rem",
            fontFamily:           theme.typography.fontFamily,
            minHeight:           "2.625rem",
            height:              "auto",
            textTransform:       "capitalize",
            padding:             "0rem 0.8rem",
            marginTop:           "0.5rem",
            letterSpacing:       "1px",
            display:             "flex",
            justifyContent:      "space-between",
            alignItems:          "center",

            "&:hover p, &:hover  a":
            {
                color:   theme.palette.primary.main
            }
        },

        navDropdownLink:
        {
            color: theme.palette.secondary.main
        },

        searchIconInput:
        {
            display:                   "flex",
            alignItems:                "center",
            paddingLeft:               "10px",
            backgroundColor:            "#ccc",
            borderRadius:              "5px",
            borderTopRightRadius:       0,
            borderBottomRightRadius:    0,
            width:                     "70%",
            maxWidth:                  "40rem",
            height:                    "2.5rem",
            transition:                "background-color 0.1s linear"
        },

        searchIcon:
        {
            fontSize:   "1.25rem",
            color:      theme.palette.primary.main    
        },

        searchTextInput:
        {
            fontFamily:         theme.typography.fontFamily,
            fontSize:          "0.8rem",
            letterSpacing:      standardLetterSpacing,
            backgroundColor:   "transparent",
            color:              theme.palette.primary.main,
            width:             "100%",
            height:            "100%",
            padding:           "0 0.5rem",
            outline:           "none",
            border:            "none",
            margin:             0,

            "&::placeholder":
            {
                color:            theme.palette.primary.main,
                letterSpacing:    standardLetterSpacing,
                userSelect:      "none"
            }
        },

        searchButton:
        {
            fontFamily:             theme.typography.fontFamily,
            fontSize:               "0.8rem",
            height:                 "2.5rem",
            borderTopLeftRadius:     0,
            borderBottomLeftRadius:  0,
            boxShadow:              "none",
            letterSpacing:           standardLetterSpacing
        },

        menuBackButton:
        {
            width: "100%",
            borderRadius: "0",
            justifyContent: "flex-start",
            marginTop:      "0.625rem",
            "&:hover":
            {
                filter: "brightness(110%)"
            },

            "& > .MuiButton-label":
            {
                justifyContent: "flex-start"
            }
        }
}));


export const useStandardButtonStyles = makeStyles((theme : Theme) => createStyles({
    standardButtonBase:
    {
        fontFamily: theme.typography.fontFamily,
        fontSize: "1rem",
        fontWeight: 300,
        letterSpacing: standardLetterSpacing,
        color: theme.palette.text.hint
    }
}));