import   React,
       { useRef }         from "react";
import {
         Link as RouterLink
       }                  from "react-router-dom";

import {
         Link
       }                  from "@material-ui/core";
import {
         makeStyles,
         createStyles
       }                  from "@material-ui/core/styles";
import {
         TypographyVariants,
         InlineStyles
       }                  from "../../interfacesTypesEnums/types";
import { themeOne }       from "../../globalThemeVariables";

interface Props
{
    ref?:                      React.MutableRefObject<HTMLElement | null> | ((instance: HTMLElement | null) => void) | null;
    baseColor?:                boolean;
    useHoverEffect?:           boolean;
    darkHover?:                boolean;
    typographyClassNames?:     string | undefined;
    typographyVariant?:        TypographyVariants;
    to?:                       string;
    isWeb?:                    boolean;
    key?:                      string | number;
    id?:                       string;
    onClick?:                  () => any;
    onMouseEnter?:             () => any;
    onMouseLeave?:             () => any;
    underline?:                "none" | "hover" | "always";
    newTab?:                   boolean;
}

const BasicTextLink : React.FC<Props> = React.memo(({
                                                    children,
                                                    key,
                                                    id,
                                                    ref,
                                                    baseColor,
                                                    useHoverEffect,
                                                    darkHover,
                                                    typographyClassNames,
                                                    to,
                                                    isWeb,
                                                    underline="none",
                                                    newTab,
                                                    onClick,
                                                    onMouseEnter,
                                                    onMouseLeave,
                                                    ...props
                                                }) =>
{
    const useStyles =  useRef(makeStyles(theme => createStyles({
        basicTextLink:
        {
            color:       baseColor ? theme.palette.text.secondary : themeOne.textColorPrimaryExtended.tertiary,
            transition: "color 0.3s linear",
            cursor:     "pointer",
            fontSize:   "inherit",
            fontFamily:  theme.typography.fontFamily,
    
            "&:hover":
            {
                color:   useHoverEffect ? (darkHover ? theme.palette.primary.main : theme.palette.primary.light) : null
            }
        },

        linkText:
        {
            display:   "inline",
            margin:    "0.25rem 0rem"
        }
    })));

    const classes        =  useStyles.current();
    const inlineStyles   =  useRef<InlineStyles>({
        routerLinkText:
        {
            textDecoration: "none"
        }
    });

    if (!isWeb) return (
        <RouterLink
            to={to ? to : ""}
            style={inlineStyles.current.routerLinkText}
            component={(props) => <>{props.children}</>}>
            <span className={[classes.linkText, typographyClassNames].join(" ")}>
                <Link
                    key={key}
                    id={id}
                    ref={ref}
                    href={to}
                    underline={underline}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    target={newTab ? "_blank" : "_self"}
                    className={[classes.basicTextLink, typographyClassNames].join(" ")}
                    {...props}>

                    {children}

                </Link>
            </span>
        </RouterLink>
    );

    return (
        <span className={[classes.linkText, typographyClassNames].join(" ")}>
            <Link
                key={key}
                ref={ref}
                href={to}
                underline={underline}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                rel={"noopener noreferrer"}
                target={newTab ? "_blank" : "_self"}
                className={[classes.basicTextLink, typographyClassNames].join(" ")}
                {...props}>

                {children}
                
            </Link>
        </span>
    );
});

export default BasicTextLink;
