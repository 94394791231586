import { createMuiTheme } from "@material-ui/core/styles"
import {
         fontFamilyOne,
         themeOne
       }                  from "./globalThemeVariables";

export const defaultTheme = createMuiTheme({
    typography:
    {
        fontFamily: fontFamilyOne
    },

    palette:
    {
        primary:    themeOne.colorTertiary,
        secondary:  themeOne.colorSecondary,
        text:       themeOne.textColorPrimary
    }
});


export const darkTheme = createMuiTheme({
    palette:
    {
        type: "dark"
    }
});