import   React             from "react";
import {
         makeStyles,
         Theme,
         createStyles
       }                   from "@material-ui/core/styles";
import   useScrollTrigger  from "@material-ui/core/useScrollTrigger";
import   Zoom              from "@material-ui/core/Zoom";

interface Props
{ }

const useStyles = makeStyles((theme: Theme) => createStyles(
  {
    root:
    {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    }
  })
);

const ScrollTop : React.FC<Props> = React.memo(({children}) =>
{
  const classes = useStyles();

  const trigger = useScrollTrigger(
  {
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) =>
  {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#back-to-top-anchor");

    if (anchor)
    {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role={"presentation"} className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
})

export default ScrollTop;