export enum GemCutShapes
{
    ROUND               =   "round",
    ASSCHER             =   "asscher",
    CUSHION             =   "cushion",
    EMERALD             =   "emerald",
    HEART               =   "heart",
    MARQUISE            =   "marquise",
    OVAL                =   "oval",
    PEAR                =   "pear",
    PRINCESS            =   "princess",
    RADIANT             =   "radiant",
    PEAR_SHAPE          =   "pear shape"
};


export enum DiamondClarityScale
{
    FL    =  "flawless",
    IF    =  "internally flawless",
    VVS1  =  "very very slightly included 1",
    VVS2  =  "very very slightly included 2",
    VS1   =  "very slightly included 1",
    VS2   =  "very slightly included 2",
    SI1   =  "slightly included 1",
    SI2   =  "slightly included 2",
    I1    =  "included 1",
    I2    =  "included 2",
    I3    =  "included 3"
};

export enum DiamondCutGradesPolishAndSymmetry
{
    EXCELLENT    =  "excellent",
    VERY_GOOD    =  "very good",
    GOOD         =  "good",
    FAIR         =  "fair",
    POOR         =  "poor",
    UNSPECIFIED  =  "unspecified"
};

export enum DiamondFluorescence
{
    VERY_STRONG = "very strong",
    STRONG      = "strong",
    MEDIUM      = "medium",
    FAINT       = "faint",
    NONE        = "none",
    UNSPECIFIED = "unspecified"
};

export enum DiamondGirdle
{
    EXTREMELY_THIN            =  "extremely thin",
    VERY_THIN                 =  "very thin",
    THIN_TO_MEDIUM            =  "thin to medium",
    SLIGHTLY_THICK_TO_THICK   =  "slightly thick to thick",
    VERY_THICK                =  "very thick",
    EXTREMELY_THICK           =  "extremely thick",
    UNSPECIFIED               =  "unspecified"
}

export enum DiamondCulet
{
    NONE                      =  "none",
    VERY_SMALL_TO_SMALL       =  "very small to small",
    MEDIUM                    =  "medium",
    SLIGHTLY_LARGE_TO_LARGE   =  "slightly large to large",
    VERY_LARGE                =  "very large",
    EXTREMELY_LARGE           =  "extremely large",
    UNSPECIFIED               =  "unspecified"
};

export enum Categories
{
    NECKLACE            =  "necklace",
    EARRING             =  "earrings",
    RING                =  "ring",
    PENDANT             =  "pendant",
    DIAMOND_STUD        =  "diamond studs",
    BRACELET            =  "bracelet",
    DIAMOND_LAB_GROWN   =  "lab grown diamond",
    DIAMOND_NATURAL     =  "natural diamond"
};

export enum JewelryCertificationDesignations
{
    GIA = "GIA",
    IGI = "IGI"
};