import   React,
       { useEffect }       			from "react";
import { Provider }       			from "react-redux";
import {
         BrowserRouter as Router
       }                  			from "react-router-dom";
import   Routes                 from "./Routes";
import { configureStore } 			from "./store";
import {
         setAuthorizationToken,
         setCurrentUser
       }                  			from './store/actions/auth';

import {
         ThemeProvider,
         Paper,
         CssBaseline,
         Grid
       }                  			from "@material-ui/core";
import {
         defaultTheme
       }                  			from "./globalTheme";
import {
         LOCAL_STORAGE_KEY_SITE_JWT_TOKEN
       }                        from "./constants/General";
import {
         APIEndpoints,
         generateEndpointConfig
       }                        from "./constants/APIEndpoints";
import { apiCall }              from "./services/api";

const store = configureStore();

interface Props
{}

const App : React.FC<Props> = () =>
{
  useEffect(() => {
    const jwtToken = localStorage.getItem(LOCAL_STORAGE_KEY_SITE_JWT_TOKEN);

    if (jwtToken)
    {
      const request = generateEndpointConfig(APIEndpoints.VALIDATE_JWT_TOKEN, { jwtToken });

      apiCall(request.METHOD, request.URL, request.DATA)
      .then(res =>
        {
          if (res.data.valid === true)
          {
            setAuthorizationToken(jwtToken);
            try
            {
              store.dispatch(setCurrentUser(res.data.user)); 
            }
            catch (error)
            {
              store.dispatch(setCurrentUser(null));    
            }
          }

          else if (res.data.valid === false)
          {
            setAuthorizationToken("");
            localStorage.removeItem(LOCAL_STORAGE_KEY_SITE_JWT_TOKEN);
            store.dispatch(setCurrentUser(null));
          }
        })
      .catch(err =>
        {
          console.warn("Unknown Error", err.response.data.message || err.message || err);
        });
    }

    else
    {
      setAuthorizationToken("");
      localStorage.removeItem(LOCAL_STORAGE_KEY_SITE_JWT_TOKEN);
      store.dispatch(setCurrentUser(null));
    }

  }, [ ]);

  
  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <Paper className="App" square elevation={0}>
            <Grid
              container
              direction={"column"}
              justify={"flex-start"}
              alignItems={"stretch"}>
              <Routes />
            </Grid>
          </Paper>
        </ThemeProvider>
      </Router>
    </Provider>
  );
}

export default App;
