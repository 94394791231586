import { Action }           from "redux";
import { ErrorActionTypes } from "../actionTypes";

export interface BaseErrorState
{
    errorMessage: string | null
}

interface ErrorReducerAction extends Action
{
    type:  ErrorActionTypes,
    error: string
}

export default (state : BaseErrorState = { errorMessage: null }, action : ErrorReducerAction) =>
{
    switch (action.type)
    {
        case ErrorActionTypes.ADD_ERROR:
            return { ...state, errorMessage: action.error };

        case ErrorActionTypes.REMOVE_ERROR:
            return { ...state, errorMessage: null };
    
        default:
            return state;
    }
}