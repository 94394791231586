import   React,
       {
         Suspense,
         useRef,
         useState,
         useEffect
       }                            from "react";
import {
         Switch,
         Route
       }                            from "react-router-dom";
import {
         Grid,
         Fab
       }                            from "@material-ui/core";
import {
         useNavbarStyles
       }                            from "./globalMUIStyles";
import   SuspenseFallbackMain       from "./components/SuspenseFallbackMain/SuspenseFallbackMain";
import   ScrollTop                  from "./components/ScrollToTop/ScrollToTop";
import   KeyboardArrowUpIcon        from "@material-ui/icons/KeyboardArrowUp";
import   BasicAlert                 from "./components/BasicAlert/BasicAlert";
import {
         COMPANY_NAME,
         LOCAL_STORAGE_KEY_HOME_TEXT_PROMO_TOP_BANNER
       }                            from "./constants/strings";
import { Routes as LocalRoutes }    from "./constants/RoutesNames";
import   WithAuth                   from "./hocs/WithAuth/WithAuth";

const  RenderNavbar           =  React.lazy(() => import("./components/RenderNavbar/RenderNavbar"));
const  SiteFooter             =  React.lazy(() => import("./components/SiteFooter/SiteFooter"));
const  NotFound               =  React.lazy(() => import("./components/NotFound/NotFound"));
const  HomePage               =  React.lazy(() => import("./pages/HomePage"));
const  LabGrown               =  React.lazy(() => import("./pages/LabGrown"));
const  AuthFlow               =  React.lazy(() => import("./pages/AuthFlow"));
const  SignUpFlow             =  React.lazy(() => import("./pages/SignUpFlow"));
const  MyAccount              =  React.lazy(() => import("./pages/MyAccount"));
const  AdminHome              =  React.lazy(() => import("./pages/AdminHome"));
const  MeleePriceGrid         =  React.lazy(() => import("./pages/MeleePriceGrid"));
const  ProcessStatus          =  React.lazy(() => import("./pages/ProcessStatus"));
const  Contact                =  React.lazy(() => import("./pages/Contact"));
const  About                  =  React.lazy(() => import("./pages/About"));
const  CategoryPage           =  React.lazy(() => import("./pages/CategoryPage"));
const  SingleProduct          =  React.lazy(() => import("./pages/SingleProduct"));
const  DiamondSearch          =  React.lazy(() => import("./pages/DiamondSearch"));
const  Wishlist               =  React.lazy(() => import("./pages/Wishlist"));
const  SearchResults          =  React.lazy(() => import("./pages/SearchResults"));
const  ResetPassword          =  React.lazy(() => import("./pages/ResetPassword"));
const  RequestAccountChange   =  React.lazy(() => import("./pages/RequestAccountChange"));

const Routes = React.memo(() => {

    const [ showTextBanner, setShowTextBanner ] = useState<boolean>(false);
    const [ showGDPR,       setShowGDPR       ] = useState<boolean>(false);

    const styles = useRef(useNavbarStyles());

    useEffect(() =>
    {
        if ([LocalRoutes.SIGN_IN, LocalRoutes.SIGN_UP, LocalRoutes.ADMIN_HOME].includes(document.location.pathname as LocalRoutes)) return;
        // TODO change condition to either shown in the past or new promotion in effect.
        if (localStorage.getItem(LOCAL_STORAGE_KEY_HOME_TEXT_PROMO_TOP_BANNER) === null) setShowTextBanner(true);
        if (localStorage.getItem("showGDPR") === "false") return;

        setShowGDPR(true);

    }, []);

    return (
        <Suspense fallback={<SuspenseFallbackMain />}>
            <div className={"routeDivContainer"}>
                {
                    showTextBanner &&
                    <BasicAlert
                        to={LocalRoutes.HOME}
                        msg={`Shop the ${COMPANY_NAME} Exclusives this Season and Save Big | Browse the Collection >`}
                        onClose={() => { localStorage.setItem(LOCAL_STORAGE_KEY_HOME_TEXT_PROMO_TOP_BANNER, "true"); }} />
                }
                <Switch>
                    <Route exact path={LocalRoutes.HOME}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"Homepage"}>
                            <HomePage />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={LocalRoutes.LAB_GROWN}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"LabGrownPage"}>
                            <LabGrown />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={LocalRoutes.SIGN_IN}>
                        <AuthFlow />
                    </Route>
                    <Route exact path={LocalRoutes.SIGN_UP}>
                        <SignUpFlow />
                    </Route>
                    <Route exact path={LocalRoutes.MY_ACCOUNT}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"MyAccount"}>
                            <WithAuth ElementToBeShown={<MyAccount />} />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={LocalRoutes.ADMIN_HOME}>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"AdminHome"}>
                            <WithAuth ElementToBeShown={<AdminHome />} />
                        </Grid>
                    </Route>
                    <Route exact path={LocalRoutes.MELEE_GRID}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"MeleePriceGrid"}>
                            <MeleePriceGrid />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={LocalRoutes.VERIFY_NSL}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"ProcessStatusPage"}>
                            <ProcessStatus />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={LocalRoutes.CONTACT_US}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"ContactPage"}>
                            <Contact />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={LocalRoutes.ABOUT_US}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"ContactPage"}>
                            <About />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={LocalRoutes.PRODS_CAT+":category"}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"CategoryPage"}>
                            <CategoryPage />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={"/product/:category/:id"}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"SingleProductPage"}>
                            <SingleProduct />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={LocalRoutes.DIAMOND_SEARCH}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"SingleProductPage"}>
                            <DiamondSearch />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>
                    <Route exact path={LocalRoutes.WISHLIST}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"SingleProductPage"}>
                            <Wishlist />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>

                    <Route exact path={LocalRoutes.SEARCH + "/:searchPhrase"}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"SingleProductPage"}>
                            <SearchResults />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>

                    <Route exact path={LocalRoutes.RESET_PASSWORD + "/:resetLink"}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"SingleProductPage"}>
                            <ResetPassword />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>

                    <Route exact path={LocalRoutes.REQUEST_ACCOUNT_CHANGE}>
                        <div id={"back-to-top-anchor"}></div>
                        <Grid item className={styles.current.topNavMain} xs={12} id={"topNavMain"}>
                            <RenderNavbar />
                        </Grid>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"MyAccount"}>
                            <WithAuth ElementToBeShown={<RequestAccountChange />} />
                        </Grid>
                        <Suspense fallback={<SuspenseFallbackMain isComponent />}>
                            <section>
                                <SiteFooter />
                            </section>
                        </Suspense>
                        <ScrollTop>
                            <Fab color={"secondary"} size={"small"} aria-label={"scroll back to top"}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Route>




                    <Route path={"*"}>
                        <Grid container item xs={12} className={"basicPageStyling"} id={"404"}>
                            <NotFound />
                        </Grid>
                    </Route>
                </Switch>
                {
                    // TODO Update on policy and
                    // cookie changes.
                    // Link this alert to the policy
                    // page.
                    showGDPR &&
                    <BasicAlert
                        to={""}
                        icon
                        fixBottom
                        msg={"To provide you with the best service, we make use of certain cookies. These cookies are stored on your computer by the use of the various storage mechanisms provided by your browser. We use cookies for various things like signing you in, keeping track of your preferences on this website, and to make sure the website is not being tampered with. By continuing to use this site you consent to our use of cookies as described in our policy."}
                        severity={"info"}
                        onClose={() => { localStorage.setItem("showGDPR", "false"); }} />
                }
            </div>
        </Suspense>)
});

export default Routes;