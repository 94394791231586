import {
         createStore,
         applyMiddleware,
         compose
       }             from "redux";
import   thunk       from "redux-thunk";

import   rootReducer from "./reducers";


export function configureStore() {
    const store = createStore(rootReducer, compose(
        applyMiddleware(thunk),
        //@ts-ignore
        (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || (f => f)
    ));

    return store;
}