import {
         apiCall,
         setTokenHeader
       }                     from "../../services/api";
import { SET_CURRENT_USER }  from "../actionTypes";

import { addError }          from "./errors";
import {
         LOCAL_STORAGE_KEY_SITE_JWT_TOKEN
       }                     from "../../constants/General";
import {
         APIEndpoints,
         generateEndpointConfig
       }                     from "../../constants/APIEndpoints";
import { UserSignInInfo }    from "../../interfacesTypesEnums/interfaces";
import { Dispatch }          from "redux";
import { ReduxUser }         from "../reducers/currentUser";


export function setCurrentUser(user : ReduxUser | null )
{
    return {
        type: SET_CURRENT_USER,
        user: user ? user : { }
    }
}

export function setAuthorizationToken(token : string)
{
    setTokenHeader(token);
}


export function logout()
{
    setAuthorizationToken("");
    localStorage.removeItem(LOCAL_STORAGE_KEY_SITE_JWT_TOKEN);
    return setCurrentUser(null);
}

export function authUser(dispatch : Dispatch,  userData : UserSignInInfo)
{
    const requestConfig = generateEndpointConfig(APIEndpoints.SIGN_IN, { credentials: userData });
    return new Promise((res, rej) =>
    {
        return apiCall(requestConfig.METHOD, requestConfig.URL, requestConfig.DATA)
                .then(({ data : {jwtToken, ...user} }) =>
                    {
                        localStorage.setItem(LOCAL_STORAGE_KEY_SITE_JWT_TOKEN, jwtToken);
                        setAuthorizationToken(jwtToken);
                        dispatch(setCurrentUser(user));
                        res();
                    })
                .catch(error =>
                    {
                        dispatch(addError(error.response.data.message));
                        rej(error.response.data.message);
                    });
    });
}