/* Site Wide Strings */

import {
         Categories,
         DiamondClarityScale,
         DiamondCulet,
         DiamondCutGradesPolishAndSymmetry,
         DiamondFluorescence,
         DiamondGirdle,
         GemCutShapes,
         JewelryCertificationDesignations
       }                from "../interfacesTypesEnums/enums";
import {
         TitleLinkItem,
         PromotionalItem,
         DropdownMenuItem,
         PromotionalBannerItem,
         CarouselImageItem
       }                from "../interfacesTypesEnums/interfaces";
import { DiamondColor } from "../interfacesTypesEnums/types";
import { Routes }       from "./RoutesNames";

// Company name must have only spaces as whitespace
export const COMPANY_NAME                =   "Channel Jewelry";
export const COMPANY_NAME_NO_SPACE       =   COMPANY_NAME.replace(/\s/g, "");
export const COMPANY_AGE                 =   30;
export const COMPANY_AGE_UNIT            =   "years";
export const COMPANY_AGE_GENERATIONS     =   "five";
export const COMPANY_CDN_BASE            =   "https://static.channelsjewelry.com/";
export const COMPANY_CDN_PRODUCTS_BASE   =   COMPANY_CDN_BASE + "products/";

export const LOCAL_STORAGE_KEY_HOME_BANNER_POPUP           =  COMPANY_NAME_NO_SPACE + "LandingPopupBannerShowed";
export const LOCAL_STORAGE_KEY_HOME_TEXT_PROMO_TOP_BANNER  =  "MainTextPromoTopBanner";

// Contact Info
export const WORKWEEK_START_STRING  =  "monday";
export const WORKWEEK_END_STRING    =  "friday";
export const STARTING_HOURS_STRING  =  "9:30";
export const CLOSING_HOURS_STRING   =  "6:30";
// If you modify this string pattern, you must also
// account for the phoneStringFormatter helper
export const PHONE_NUMBER_STRING    =  "18006789263";
export const COMPANY_EMAIL_INFO     =  "info@channelsjewelry.com";

export const NEWSLETTER_SIGNUP_CONFIRMATION = "We have sent you a confirmation on the email you provided.";


/* Navbar | file: /src/containers/Navbar.tsx */

// Menu titles for the dropdown menu on the top navbar
export const ACCOUNT_STRING    =  "Account";
export const WISHLIST_STRING   =  "Wishlist";
export const SIGN_IN_STRING    =  "Sign In";
export const SIGN_OUT_STRING   =  "Sign Out";

// Navbar top menu links
export const DROPDOWN_TITLES : Array<DropdownMenuItem> =
[
    {title: ACCOUNT_STRING,  url: Routes.MY_ACCOUNT},
    {title: WISHLIST_STRING, url: Routes.WISHLIST},
    {title: SIGN_OUT_STRING, url: ""}
];

export const DROPDOWN_TITLES_MOBILE_EXTENSION : Array<DropdownMenuItem> =
[
    {title: SIGN_IN_STRING, url: Routes.SIGN_IN}
];

// Suspense loading component filler text

export const SUSPENSE_LOADING_TEXT = "Loading";


/* NavBottomBar | file: /src/components/NavBottomBar/NavBottomBar.tsx */


// Navbar main menu titles and  links
export const NAVBAR_MENU_TITLES : Array<string> =
[
    // "Engagement Rings",
    "Jewelry",
    "Diamonds",
    "Company"
    // "Contact Us"
];

// Dropdown titles for Engagement Rings
export const ENGAGEMENT_RINGS_DROPDOWN_TITLES : Array<DropdownMenuItem> =
[
    { title: "Solitaire",      url: "" },
    { title: "Halo",           url: "" },
    { title: "Bypass",         url: "" },
    { title: "Wedding Bands",  url: "" },
    { title: "Channel Set",    url: "" }
];

// Dropdown titles for Diamonds
export const DIAMONDS_DROPDOWN_TITLES : Array<DropdownMenuItem> =
[
    { title: "Melee Price Grid",  url: Routes.MELEE_GRID },
    { title: "Lab Grown",         url: Routes.LAB_GROWN },
    { title: "Diamond Search",    url: Routes.DIAMOND_SEARCH }
    // { title: "Certificate",       url: "" }
];


// IMPORTANT: Keep the URLs in line with
// the product schema on the server!

// Dropdown titles for Jewelry
export const JEWELRY_DROPDOWN_TITLES : Array<DropdownMenuItem> =
[
    { title: "Necklaces",        url: Routes.PRODS_CAT + "necklace" },
    { title: "Earrings",         url: Routes.PRODS_CAT + "earrings" },
    { title: "Diamond Studs",    url: Routes.PRODS_CAT + "diamond%20studs" },
    { title: "Rings",            url: Routes.PRODS_CAT + "ring" },
    { title: "Pendants",         url: Routes.PRODS_CAT + "pendant" },
    { title: "Bracelets",        url: Routes.PRODS_CAT + "bracelet" }
];


// const companyEducationSubMenu : Array<DropdownMenuItem> =
// [
//     { title: "Gemology School",                     url: "" },
//     { title: "How to Read A Diamond Certificate",   url: "" }
// ];

// Dropdown titles for Company
export const COMPANY_RINGS_DROPDOWN_TITLES : Array<DropdownMenuItem> =
[
    { title: "About Us",          url: Routes.ABOUT_US },
    { title: "Contact Us",        url: Routes.CONTACT_US }
    // { title: "Education",         url: "", subMenu: companyEducationSubMenu},
    // { title: "Our Promise",       url: "" }
];

// Brand logo title (nav)
export const BRAND_LOGO_TITLE_STRING        = COMPANY_NAME_NO_SPACE;

// Brand logo subtitle (nav)
export const BRAND_LOGO_SUBTITLE_STRING     = "A C.M. Diamond Initiative";

// Navbar bottom center right
// { [key:string] : TitleLinkItem | string } 
export const HELPER_STRINGS =
{
    // First helper link string
    NAV_HELPER_LINK_ONE:     { title: "Lab Grown",            url: Routes.LAB_GROWN },
    
    // Second helper link string
    NAV_HELPER_LINK_TWO:     { title: "Newsletter",           url: "" },
    
    // Third helper link string
    NAV_HELPER_LINK_THREE:   { title: "Book a Consultation",  url: Routes.CONTACT_US },
}

export const HELPER_STRINGS_TEXT =
{
    // Navbar search input field prompt
    NAV_SEARCH_BAR_PROMPT:   "Search"
}

/* SiteFooter | file: /src/components/SiteFooter/SiteFooter.tsx */

// Footer Section Titles

export const FOOTER_SECTION_TITLES : Array<string> =
[
    "Shop",
    "Connect",
    "About " + COMPANY_NAME,
    "Newsletter Signup"
];

const FOOTER_SHOP_LINKS : Array<TitleLinkItem> =
[
    {title: "Engagement Rings", url: Routes.PRODS_CAT + "ring"},
    {title: "Diamonds",         url: Routes.DIAMOND_SEARCH},
    {title: "Jewelry",          url: Routes.PRODS_CAT + "necklace"}
];

const FOOTER_CONNECT_LINKS : Array<TitleLinkItem> =
[
    { title: "Facebook",  url: "https://www.facebook.com/channeljewelry"   },
    { title: "Twitter",   url: "https://www.twitter.com/channeljewelry"    },
    { title: "Instagram", url: "https://www.instagram.com/channeljewelry/" }
];

export const ABOUT_US_TEXT : [string] = [`At ${COMPANY_NAME} we like to keep things simple. A ${COMPANY_AGE_GENERATIONS} generation, family owned and operated business which is here to serve you. ${COMPANY_NAME} constantly strives to live up to its reputation of being trustworthy, competitive and most importantly, humble. Our roots lie in our customers and we hope your experience here will help us prove that.`];

const NEWSLETTER_SIGNUP_PROMPT : [string] = ["Don't miss out our monthly News & Specials! By signing up you agree to our privacy policy and terms of use"];

export const FOOTER_SECTION_TITLE_LINKS : Array<Array<TitleLinkItem>> =
[
    FOOTER_SHOP_LINKS,
    FOOTER_CONNECT_LINKS
];

export const FOOTER_SECTION_TITLE_INFOS : Array<Array<string>> =
[
    ABOUT_US_TEXT,
    NEWSLETTER_SIGNUP_PROMPT
];

/* Reasons to Buy Component | file: src/component/ReasonsToBy/ReasonsToBuy.tsx */

export const REASONS_TO_BUY_LIST : Array<string> =
[
    "Excellent Customer Service",

    "High Quality",
    
    "Fractional Cost",
    
    "30-Day Return Policy",
    
    "Made to Order",
    
    "Conflict Free"
];

/* Reasons to Buy Cards */

export const REASONS_TO_BUY_FEATURES : Array<[string, string]> =
[
    ["Ethical","We pride ourselves in promoting humane labor practices."],
    ["Economical","No more buyers remorse! Highest value and quality all-in-one."],
    ["Sustainable","Minimal impact on the environment. Enjoy guilt free diamonds."],
    ["Authentic","There is no difference between a mined diamond and a lab grown diamond."]
];

export const FEATURES : Array<[string, string]> =
[
    ["Shipping","At checkout, free insured shipping will be automatically included after you enter the address."],
    ["Returns","If you are not fully satisfied with your unique piece, you have 30 days to return it for a refund."],
    ["Packaging","Every piece is securely shipped with its own box and special complimentary packaging."],
    ["Made To Order",`Each piece at ${COMPANY_NAME} is made to order to ensure the highest level of attention to detail.`]
];


/* ===================================== PAGES ===================================== */

/* HomePage | file: src/pages/HomePage/HomePage.tsx */

export const HOMEPAGE_EXPLORE_CATEGORIES_TITLES : Array<{ title: TitleLinkItem["title"], url: TitleLinkItem["url"], imgURL: string }> =
[
    { title: "Engagement Rings",       url: Routes.PRODS_CAT + "ring",      imgURL: require("../assets/images/homepageExplore/engagementRing.webp") },
    { title: "Wedding Rings",          url: Routes.PRODS_CAT + "ring",      imgURL: require("../assets/images/homepageExplore/weddingRing.webp") },
    { title: "Diamonds",               url: Routes.DIAMOND_SEARCH,          imgURL: require("../assets/images/homepageExplore/diamonds.webp") },
    { title: "Diamond Jewelry",        url: Routes.PRODS_CAT + "earrings",  imgURL: require("../assets/images/homepageExplore/jewelry.webp") },
    { title: "Earrings",               url: Routes.PRODS_CAT + "earrings",  imgURL: require("../assets/images/homepageExplore/earrings.webp") },
    { title: "Necklaces",              url: Routes.PRODS_CAT + "necklace",  imgURL: require("../assets/images/homepageExplore/necklace.webp") }
];


export const HOMEPAGE_CAROUSEL_IMAGE_DESCRIPTIONS : Array<CarouselImageItem> =
[
    { desc: "Diamond Search", identifier: "1"},
    { desc: "A Collection of The Finest Jewelry", identifier: "0"}
];

export const HOMEPAGE_SPECIALS_STRING           =  "Specials";
export const HOMEPAGE_EXPLORE_STRING            =  "Explore";
export const HOMEPAGE_FEATURED_SECTION_TITLE    =  "Featured";
export const HOMEPAGE_AFFILIATES_SECTION_TITLE  =  "Affiliates";

export const PROMOTIONAL_BANNER : Array<PromotionalBannerItem> =
[
    { linkURL: Routes.DIAMOND_SEARCH,        imgURL: "", imgAltText: "Diamond Search",                   },
    { linkURL: Routes.DIAMOND_SEARCH,        imgURL: "", imgAltText: "Diamond Fancies",                  },
    { linkURL: Routes.PRODS_CAT + "earring", imgURL: "", imgAltText: "Collections of The Finest Jewelry" }
];

export const PROMOTIONAL_PRODUCT_IMG_URL_PARTIAL_DEFAULT =  "../../assets/images/homepagePromotional/";


export const PROMOTIONAL_PRODUCT_TITLE_SUB_LINK : Array<PromotionalItem> =
[
    {
        promoHeader:         "See Diamond Prices",
        promoSubtitle:       "See The Full Melee Price List",
        displayImgAltText:   "Single Diamond",
        buttonText:          "See Price Grid",
        promotionTargetURL:  Routes.MELEE_GRID
    },

    {
        promoHeader:         "Shop All Rings",
        promoSubtitle:       COMPANY_NAME + " Exclusives",
        displayImgAltText:   "Bridal Engagement Ring Collection",
        buttonText:          "Shop Now",
        promotionTargetURL:  Routes.PRODS_CAT + "ring"
    }
];

/* LabGrown | file: src/pages/LabGrown/LabGrown.tsx */




/* LabGrownFAQs | file: src/components/LabGrownFAQ/LabGrownFAQ.tsx */

// The need for the format of the faqs will become apparent
// where they are rendered
const FAQ_PT1 = `Do lab diamonds sparkle rainbow?|
No, Lab Grown or mined diamonds do not sparkle rainbow. Some stones like Moissanite, Cubic Zirconia or a Rhinestone may shine rainbow,but not diamonds.@
Can I grow diamonds at home?|
Yes. If you have a deep knowledge about diamonds and its associated characteristics, are a chemical scientist and can develop a lab in your garage with all the right equipment, you may grow Lab Grown Diamonds at home. But,remember, your cost will be higher due to investment in all the above.&Why not leave it to experts then?@
Will lab diamonds pass diamond tests?|
Yes, they will!&Some of the most well known diamond grading institutes like, Gemological Institute of America (GIA), American Gem Society (AGS), International Gemological Institute (IGI), European Gemological Laboratory (EGL), Hoge Raad Voor Diamant (HRD), to name a few, offer laboratory-grown diamond grading reports using the same color and clarity boundaries as the grading system for natural diamonds.&Lab-grown diamonds are subjected to the same tests to determine their chemical, physical, and optical properties as mined diamonds.@
Are lab-grown diamonds a threat to the diamond industry?|
No, absolutely not! They are not a threat to diamond industry, but they are a disrupter. By being economical, environment friendly and ethical, lab grown diamonds have brought fresh perspective to the diamond industry.@
Are lab grown diamonds real?|
Lab grown diamonds are 100% real and as much authentic as mined diamonds. They can not be distinguished from a mined diamond with naked eyes. By replicating the way nature creates diamonds from stones, we now have lab-created diamonds that are structurally identical and give peace of mind to conscious buyers.@
Are lab grown diamonds as beautiful as earth mined?|
Not only are lab-grown diamonds as breathtakingly beautiful as mined diamonds, they are easily available too. A typical lab-grown diamond is made available to you in a matter of weeks. The added facilities like color and size customization is also possible.&That means that you can have 20 identical pink lab-grown diamonds- a rarity if you set out to find similar mined diamonds. By reducing the steps in sourcing a diamond and completely doing away with middlemen, we can assure you that you can have economical, ethical and environmentally conscious diamonds for your diamond jewelry which are at par with their mined counterparts.@
Does Diamond Clarity affect sparkle?|
Diamonds are nothing but shiny carbon surfaces juxtaposed together at different angles. If the inner surfaces have some impurities or imperfections (called inclusions), the diamond might not sparkle as much.&The more included a diamond, the less reflection it will have.Almost all diamonds have inclusions; in fact, perfectly flawless diamonds are so rare that most jewelers will never see one.Fortunately, most inclusions can only be seen under 10x magnification, so they are unnoticeable to the naked, untrained eye.&Diamonds with large inclusions, or a large number of inclusions, will have a low clarity grade.@
How to clean cloudy diamonds?|
A clouded diamond can not be cleaned. The impurity causing the clouding is buried deep under the surface of the diamond, and it can not be reached through any process.@
What is Cubic Zirconia? Does it look real?|
Cubic Zirconia is a colorless, synthetic gemstone made of the cubic crystalline form of zirconium dioxide. Cubic Zirconia is not real and is not a diamond, though it is difficult for the naked eye to tell the difference between a Cubic Zirconia and a diamond, with practice you can tell that CZ does not look like the real deal.&CZ stones are not graded by any grading institutes and they are at best a cheap imitation of diamonds.@
How does a diamond become cloudy?|
A diamond becomes cloudy during the formation process itself. Due to impurities or inclusions embedded while the core of diamond was getting formed, the diamonds look slightly hazy or milky when looked closely. It is a natural intrinsic characteristics of a diamond.@
What are the four C's to diamond buying?|
GIA established a system that has now become the universal standard for diamond grading - the 4C's of diamonds. A universal language understood across the globe while grading the precious diamonds.The 4 C's stand for Color, Cut, Clarity, and Carat Weight.`.split("@").map(el => el.split("|"));

const FAQ_PT2 = `Why do diamonds sparkle even in dim light?|
Diamonds have a high refractive index, but that's just one of the reason for their sparkle even in dark. The light ray passing through a diamond travels through scores of reflective surfaces inside the diamond, and that is the reason for the brilliance you see.The shape and cut of a diamond decides its shine.&A well cut diamond that has passed through the skilled hands will refract even the tiniest ray of light falling on it. By the way, round diamonds are known for better sparkling abilities.@
Are lab-diamonds heavy?|
No, they are light stones.&Diamonds are dense, compact pieces of pure carbon converted due to high heat and pressure conditions. With a specific gravity of 3.52, diamonds are much much lighter than Gold, which has a specific gravity of 19.32.&In fact, the light weight of diamond is one of the determinants of whether a diamond is real or fake. Cubic Zirconia, often passed on as diamond will weight at least 50% more than a real diamond of same size.@
Do man-made diamonds have color?|
Yes, man-made diamonds are available in vivid colors like pink, blue, green etc. They are created by mimicking the same conditions as mined color diamonds encounter inside the earth. Its not just white diamonds that can be created in a lab.&The fancy colors that you see in these exceptional creations by humans are introduced by adding color-causing atoms to the growth cell during the growth phase, while the diamond's crystal lattice is still developing. Want yellow diamonds? Add Nitrogen. Fancy blue diamonds? Add Boron.@
Are lab-grown diamonds valueless?|
No, its a myth.&When you buy a lab-grown diamond that is certified by a reputed grading institute,rest assured that you can get similar value when you are trying to resell it. Lab grown diamonds have the same resell process as a regular mined diamond.&Regardless of the origin, all diamonds are priced using a universal pricing system based on their grading on 4Cs. If your jeweler is unable to accept your diamond- it is just because of the misconceptions surrounding the lab-grown diamonds. Your certificate entitles you to the same resale process as any regular mined diamond.@
How expensive are lab grown diamonds? |
Lab-grown diamonds are significantly less expensive than mined diamonds. But lower price has no bearing on their quality. They have all the characteristics of a mined diamond. The process of sourcing a lab-grown diamond is relatively easier as well as faster and hence they are relatively less expensive than mined diamonds.@
What are the differences between VVS / VS /VS1 / VS2 diamonds?|
The terms are used to define the various clarity grades of a diamonds. VVS stands for "Very Very Slight" inclusive diamonds - having the least amount of imperfection or impurity.&These also are the most expensive of the remaining categories. VS is " Very Slight" inclusive diamonds- that means they do have more inclusions than VVS, are not as "near perfect " as VVS, but they are still a good bet to buy. Very slightly included (VS1 and VS2) diamonds have minor inclusions ranging from difficult (VS1) to somewhat easy (VS2) to see with an increase of 10 × magnification.@
How rare is it to find VVS diamonds naturally?|
Diamonds are definitely not rare - they are just difficult to find and extract. It is not difficult to find a VVS clarity diamond.@
What do jewelers use to look at diamonds?|
Jewelers typically use a monocular, handheld loupe in order to magnify gemstones and other jewelry that they wish to inspect. A 10× magnification is good to use for inspecting jewelry and hallmarks and is the Gemological Institute of America's standard for grading diamond clarity.@
Is the naked eye sufficient for appraising jewelry?|
No.&Diamonds on the lowest category of clarity spectrum also have inclusions that are not visible to the naked eye.To evaluate a diamond and to determine its authenticity, you must have the right equipment as well as the right education (Certified Gemologist) to appraise diamond jewelry.`.split("@").map(el => el.split("|"));

export const LGFAQS = [FAQ_PT1, FAQ_PT2];


export const CategoriesArray : Array<Categories> =
[
    Categories.NECKLACE,
    Categories.EARRING,
    Categories.RING,
    Categories.PENDANT,
    Categories.DIAMOND_STUD,
    Categories.BRACELET,
    Categories.DIAMOND_LAB_GROWN,
    Categories.DIAMOND_NATURAL
];

export const JewelryCertificationDesignationsArray : Array<JewelryCertificationDesignations> =
[
    JewelryCertificationDesignations.GIA,
    JewelryCertificationDesignations.IGI
];

export const certSelect : Array<[JewelryCertificationDesignations | ""]> =
[
    [ "" ],
    [ JewelryCertificationDesignations.GIA ],
    [ JewelryCertificationDesignations.IGI ]
];

export const cutSelect : Array<[GemCutShapes, GemCutShapes]> =
[
    [ GemCutShapes.ROUND,       GemCutShapes.ROUND        ],
    [ GemCutShapes.ASSCHER,     GemCutShapes.ASSCHER      ],
    [ GemCutShapes.CUSHION,     GemCutShapes.CUSHION      ],
    [ GemCutShapes.EMERALD,     GemCutShapes.EMERALD      ],
    [ GemCutShapes.HEART,       GemCutShapes.HEART        ],
    [ GemCutShapes.MARQUISE,    GemCutShapes.MARQUISE     ],
    [ GemCutShapes.OVAL,        GemCutShapes.OVAL         ],
    [ GemCutShapes.PEAR,        GemCutShapes.PEAR         ],
    [ GemCutShapes.PEAR_SHAPE,  GemCutShapes.PEAR_SHAPE   ],
    [ GemCutShapes.PRINCESS,    GemCutShapes.PRINCESS     ],
    [ GemCutShapes.RADIANT,     GemCutShapes.RADIANT      ]
];

export const diamondClaritySelect : Array<[DiamondClarityScale, DiamondClarityScale]> =
[
    [ DiamondClarityScale.FL,   DiamondClarityScale.FL   ],
    [ DiamondClarityScale.IF,   DiamondClarityScale.IF   ],
    [ DiamondClarityScale.VVS1, DiamondClarityScale.VVS1 ],
    [ DiamondClarityScale.VVS2, DiamondClarityScale.VVS2 ],
    [ DiamondClarityScale.VS1,  DiamondClarityScale.VS1  ],
    [ DiamondClarityScale.VS2,  DiamondClarityScale.VS2  ],
    [ DiamondClarityScale.SI1,  DiamondClarityScale.SI1  ],
    [ DiamondClarityScale.SI2,  DiamondClarityScale.SI2  ],
    [ DiamondClarityScale.I1,   DiamondClarityScale.I1   ],
    [ DiamondClarityScale.I2,   DiamondClarityScale.I2   ],
    [ DiamondClarityScale.I3,   DiamondClarityScale.I3   ],
];

export const diamondCutGradePolishAndSymmetrySelect : Array<[DiamondCutGradesPolishAndSymmetry, DiamondCutGradesPolishAndSymmetry]> =
[
    [ DiamondCutGradesPolishAndSymmetry.EXCELLENT,    DiamondCutGradesPolishAndSymmetry.EXCELLENT   ],
    [ DiamondCutGradesPolishAndSymmetry.VERY_GOOD,    DiamondCutGradesPolishAndSymmetry.VERY_GOOD   ],
    [ DiamondCutGradesPolishAndSymmetry.GOOD,         DiamondCutGradesPolishAndSymmetry.GOOD        ],
    [ DiamondCutGradesPolishAndSymmetry.FAIR,         DiamondCutGradesPolishAndSymmetry.FAIR        ],
    [ DiamondCutGradesPolishAndSymmetry.POOR,         DiamondCutGradesPolishAndSymmetry.POOR        ],
    [ DiamondCutGradesPolishAndSymmetry.UNSPECIFIED,  DiamondCutGradesPolishAndSymmetry.UNSPECIFIED ]
];

export const diamondFluorescenceSelect : Array<[DiamondFluorescence, DiamondFluorescence]> =
[
    [ DiamondFluorescence.VERY_STRONG,   DiamondFluorescence.VERY_STRONG    ],
    [ DiamondFluorescence.STRONG,        DiamondFluorescence.STRONG       ],
    [ DiamondFluorescence.MEDIUM,        DiamondFluorescence.MEDIUM       ],
    [ DiamondFluorescence.FAINT,         DiamondFluorescence.FAINT        ],
    [ DiamondFluorescence.NONE,          DiamondFluorescence.NONE         ],
    [ DiamondFluorescence.UNSPECIFIED,   DiamondFluorescence.UNSPECIFIED  ]
];

export const diamondColorSelect : Array<DiamondColor> =
[
    "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
];

export const diamondGirdleSelect : Array<DiamondGirdle> =
[
    DiamondGirdle.EXTREMELY_THICK,
    DiamondGirdle.EXTREMELY_THIN,
    DiamondGirdle.SLIGHTLY_THICK_TO_THICK,
    DiamondGirdle.THIN_TO_MEDIUM,
    DiamondGirdle.VERY_THICK,
    DiamondGirdle.VERY_THIN,
    DiamondGirdle.UNSPECIFIED
];

export const diamondCuletSelect : Array<DiamondCulet> =
[
    DiamondCulet.EXTREMELY_LARGE,
    DiamondCulet.MEDIUM,
    DiamondCulet.NONE,
    DiamondCulet.SLIGHTLY_LARGE_TO_LARGE,
    DiamondCulet.VERY_LARGE,
    DiamondCulet.VERY_SMALL_TO_SMALL,
    DiamondCulet.UNSPECIFIED
];

export const PRODUCT_IMAGE_PLACEHOLDER_URL = "https://static.channelsjewelry.com/products/Logo_sm.png";

export const singularCutSelect : Array<GemCutShapes> =
[
    GemCutShapes.ROUND,
    GemCutShapes.ASSCHER,
    GemCutShapes.CUSHION,
    GemCutShapes.EMERALD,
    GemCutShapes.HEART,
    GemCutShapes.MARQUISE,
    GemCutShapes.OVAL,
    GemCutShapes.PEAR,
    GemCutShapes.PEAR_SHAPE,
    GemCutShapes.PRINCESS,
    GemCutShapes.RADIANT
];

export const singularDiamondClaritySelect : Array<DiamondClarityScale> =
[
    DiamondClarityScale.FL,
    DiamondClarityScale.IF,
    DiamondClarityScale.VVS1,
    DiamondClarityScale.VVS2,
    DiamondClarityScale.VS1,
    DiamondClarityScale.VS2,
    DiamondClarityScale.SI1,
    DiamondClarityScale.SI2,
    DiamondClarityScale.I1,
    DiamondClarityScale.I2,
    DiamondClarityScale.I3,
];

export const singularDiamondCutGradePolishAndSymmetrySelect : Array<DiamondCutGradesPolishAndSymmetry> =
[
    DiamondCutGradesPolishAndSymmetry.EXCELLENT,
    DiamondCutGradesPolishAndSymmetry.VERY_GOOD,
    DiamondCutGradesPolishAndSymmetry.GOOD,
    DiamondCutGradesPolishAndSymmetry.FAIR,
    DiamondCutGradesPolishAndSymmetry.POOR,
    DiamondCutGradesPolishAndSymmetry.UNSPECIFIED
];

export const singularDiamondFluorescenceSelect : Array<DiamondFluorescence> =
[
    DiamondFluorescence.VERY_STRONG,
    DiamondFluorescence.STRONG,
    DiamondFluorescence.MEDIUM,
    DiamondFluorescence.FAINT,
    DiamondFluorescence.NONE,
    DiamondFluorescence.UNSPECIFIED
];