import { UserSignInInfo }       from "../interfacesTypesEnums/interfaces";
import { ValidAPICallMethods }  from "../services/api";

export type PrimitiveAPIData = string | number | boolean | Array<string | number | boolean>;
export type ComposedAPIData  = { readonly [key: string] : PrimitiveAPIData | UserSignInInfo | ComposedAPIData };

interface APIEndPointConfig
{
    readonly URL:    string;
    readonly METHOD: ValidAPICallMethods;
    readonly DATA:
    {
        readonly [key : string] : PrimitiveAPIData | ComposedAPIData
    };
}

enum BaseURLS
{
    API           = "/api",
    USERS         = "/users",
    PRODUCTS      = "/products",
    FILES         = "/files"
}

// All endpoints are relative to
// <server_url>/api
export enum APIEndpoints
{
    // User Endpoints
    REQUEST_NEW_ACCOUNT      =  BaseURLS.API + BaseURLS.USERS     + "/request",
    VALIDATE_TOKEN           =  BaseURLS.API + BaseURLS.USERS     + "/validate",
    VALIDATE_JWT_TOKEN       =  BaseURLS.API + BaseURLS.USERS     + "/validatejwt",
    UPDATE_PASSWORD          =  BaseURLS.API + BaseURLS.USERS     + "/update-password",
    GET_USER                 =  BaseURLS.API + BaseURLS.USERS     + "/user/",
    GET_ALL_USERS            =  BaseURLS.API + BaseURLS.USERS     + "/all/",
    UPDATE_USER              =  BaseURLS.API + BaseURLS.USERS     + "/update",
    SIGN_IN                  =  BaseURLS.API + BaseURLS.USERS     + "/signin",
    GET_ALL_PENDING          =  BaseURLS.API + BaseURLS.USERS     + "/get-pending",
    SET_USER_STATUS          =  BaseURLS.API + BaseURLS.USERS     + "/set-status",
    SET_ADMIN                =  BaseURLS.API + BaseURLS.USERS     + "/set-admin",
    NEWSLETTER_REQ           =  BaseURLS.API + BaseURLS.USERS     + "/newsletter-signup-request",
    VERIFY_NSL               =  BaseURLS.API + BaseURLS.USERS     + "/verify-newsletter-email/",
    CONTACT_US               =  BaseURLS.API + BaseURLS.USERS     + "/contact-us",
    REQUEST_PASS_RESET       =  BaseURLS.API + BaseURLS.USERS     + "/reset-password",
    PASSWORD_RESET           =  BaseURLS.API + BaseURLS.USERS     + "/reset-password/",
    REQUEST_CHANGE           =  BaseURLS.API + BaseURLS.USERS     + "/request-account-change",
    GET_MODIFY_REQUESTS      =  BaseURLS.API + BaseURLS.USERS     + "/get-modify-requests",
    SET_PERMANENT_STATUS     =  BaseURLS.API + BaseURLS.USERS     + "/set-permanent-status",
    GET_PERMANENT_STATUS     =  BaseURLS.API + BaseURLS.USERS     + "/get-permanent-status/",
    DELETE_USER              =  BaseURLS.API + BaseURLS.USERS     + "/delete/",
    GET_STATIC_FILE          =  BaseURLS.API + BaseURLS.FILES     + "/remote",
    GET_FILE_ID              =  BaseURLS.API + BaseURLS.FILES     + "/remote/",
    CREATE_PRODUCTS          =  BaseURLS.API + BaseURLS.PRODUCTS  + "/new/",
    BULK_UPLOAD_DIAMOND      =  BaseURLS.API + BaseURLS.PRODUCTS  + "/bulk-upload-diamond",
    GET_PRESIGNED_URL        =  BaseURLS.API + BaseURLS.PRODUCTS  + "/get-presigned",
    GET_SPECIALS             =  BaseURLS.API + BaseURLS.PRODUCTS  + "/specials",
    GET_CATEGORY             =  BaseURLS.API + BaseURLS.PRODUCTS  + "/category/",
    GET_PRODUCT              =  BaseURLS.API + BaseURLS.PRODUCTS  + "/product",
    GET_IMAGE_URLS           =  BaseURLS.API + BaseURLS.PRODUCTS  + "/image-list",
    GET_ALL_PRODUCTS         =  BaseURLS.API + BaseURLS.PRODUCTS  + "/all/",
    DIAMOND_SEARCH           =  BaseURLS.API + BaseURLS.PRODUCTS  + "/search-these-diamonds",
    SEARCH_PRODUCTS          =  BaseURLS.API + BaseURLS.PRODUCTS  + "/text-search",
    UPDATE_PRODUCTS          =  BaseURLS.API + BaseURLS.PRODUCTS  + "/update",
    DELETE_PRODUCTS          =  BaseURLS.API + BaseURLS.PRODUCTS  + "/delete/",
}

// Must be careful, multiple properties with the same
// name can be defined.

// Must learn more TypeScript!
const APIEndpointMethodMap : { [ key : string ]: ValidAPICallMethods } =
{
    [APIEndpoints.REQUEST_NEW_ACCOUNT    + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.VALIDATE_TOKEN         + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.VALIDATE_JWT_TOKEN     + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.UPDATE_PASSWORD        + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.GET_USER               + ""]: ValidAPICallMethods.GET,
    [APIEndpoints.GET_ALL_USERS          + ""]: ValidAPICallMethods.GET,
    [APIEndpoints.UPDATE_USER            + ""]: ValidAPICallMethods.PUT,
    [APIEndpoints.SIGN_IN                + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.GET_ALL_PENDING        + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.SET_USER_STATUS        + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.SET_ADMIN              + ""]: ValidAPICallMethods.PUT,
    [APIEndpoints.NEWSLETTER_REQ         + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.VERIFY_NSL             + ""]: ValidAPICallMethods.GET,
    [APIEndpoints.CONTACT_US             + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.REQUEST_PASS_RESET     + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.PASSWORD_RESET         + ""]: ValidAPICallMethods.GET,
    [APIEndpoints.REQUEST_CHANGE         + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.GET_MODIFY_REQUESTS    + ""]: ValidAPICallMethods.GET,
    [APIEndpoints.SET_PERMANENT_STATUS   + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.GET_PERMANENT_STATUS   + ""]: ValidAPICallMethods.GET,
    [APIEndpoints.DELETE_USER            + ""]: ValidAPICallMethods.DELETE,
    [APIEndpoints.GET_STATIC_FILE        + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.GET_FILE_ID            + ""]: ValidAPICallMethods.GET,
    [APIEndpoints.CREATE_PRODUCTS        + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.BULK_UPLOAD_DIAMOND    + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.GET_PRESIGNED_URL      + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.GET_SPECIALS           + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.GET_CATEGORY           + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.GET_IMAGE_URLS         + ""]: ValidAPICallMethods.GET,
    [APIEndpoints.GET_ALL_PRODUCTS       + ""]: ValidAPICallMethods.GET,
    [APIEndpoints.DIAMOND_SEARCH         + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.SEARCH_PRODUCTS        + ""]: ValidAPICallMethods.POST,
    [APIEndpoints.UPDATE_PRODUCTS        + ""]: ValidAPICallMethods.PUT,
    [APIEndpoints.DELETE_PRODUCTS        + ""]: ValidAPICallMethods.DELETE
};

export const generateEndpointConfig  = (endpoint: APIEndpoints | string, data?: PrimitiveAPIData | ComposedAPIData | { [key : string] : any }) =>
(Object.freeze({
    URL:     endpoint,
    METHOD:  APIEndpointMethodMap[endpoint],
    DATA:    data
}) as APIEndPointConfig);