import   React, { useRef }                from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import { useSelector }        from "react-redux";
import   BasicTextLink        from "../../components/BasicLink/BasicLink";
import { Routes }             from "../../constants/RoutesNames";
import { DefaultRootState }   from "../../store/reducers";

interface Props
{
    ElementToBeShown: React.ReactElement;
};

const isAuthenticatedSelector = (state: DefaultRootState) => state.currentUser.isAuthenticated;

const useStyles = makeStyles({
        loginMessageContainer:
        {
              width:              "100%",
              height:             "20rem",
              display:            "flex",
              alignItems:         "center",
              justifyContent:     "center"
        }
});
const WithAuth : React.FC<Props> = React.memo(({ ElementToBeShown }) =>
{
  const isAuthenticated = useSelector<DefaultRootState, boolean>(isAuthenticatedSelector);
  const classes = useRef(useStyles()).current;

  return (
    isAuthenticated ? ElementToBeShown : 
      <Container className={classes.loginMessageContainer}>
          <Typography component={"h1"} variant={"h4"} align={"center"} color={"primary"}>
          You are not signed in. Please {
          <BasicTextLink baseColor useHoverEffect darkHover to={Routes.SIGN_IN} underline={"hover"}>
            sign in
          </BasicTextLink>
          } first
          </Typography>
      </Container>
  )
});

export default WithAuth;
