export const fontFamilyOne         =  "'ubuntu'";
export const standardLetterSpacing =  "2px";

export const themeOne =
{
    colorPrimary:
    {
        light: "#a9d9b5",
        main:  "#c66961",
        dark:  ""
    },

    colorSecondary:
    {
        light: "#f99c94",
        main:  "#f88379",
        dark:  "#c66961"
    },

    colorTertiary:
    {
        light: "#008CD2",
        main:  "#005",
        dark:  "#567880"
    },

    textColorPrimary:
    {
        primary:   "#6b5a5a",
        secondary: "#5d5a7a",
        hint:      "#DEE7E9"
    },

    textColorPrimaryExtended:
    {
        primary:   "#6b5a5a",
        secondary: "#5d5a7a",
        tertiary:  "#fff",
        hint:      "#00FFFF"
    },

    textColorSecondary:
    {
        light: "",
        main:  "",
        dark:  ""
    },

    textColorTertiary:
    {
        light: "",
        main:  "",
        dark:  ""
    }
};