export enum Routes
{
    HOME                    = "/",
    LAB_GROWN               = "/lab-grown",
    SIGN_IN                 = "/sign-in",
    SIGN_UP                 = "/sign-up",
    MY_ACCOUNT              = "/my-account",
    ADMIN_HOME              = "/admin",
    MELEE_GRID              = "/melee-grid",
    VERIFY_NSL              = "/verify-newsletter-email/:confirmation",
    CONTACT_US              = "/contact",
    ABOUT_US                = "/about",
    PRODS_CAT               = "/products/category/",
    SINGLE_PROD             = "/product/",
    DIAMOND_SEARCH          = "/diamonds/",
    WISHLIST                = "/wishlist",
    SEARCH                  = "/search",
    RESET_PASSWORD          = "/reset-password",
    REQUEST_ACCOUNT_CHANGE  = "/request-account-change"
};